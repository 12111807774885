import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Card from '../components/Card'

import Slider from '../components/Slider'


import box1 from '../images/box/1.jpg'
import box2 from '../images/box/2.jpg'
import box3 from '../images/box/3.jpg'

// import slide1 from '../images/slider/1.jpg'
import slide2 from '../images/paczki.jpg'
// import slide3 from '../images/slider/3.jpg'

const boxes = [
  // {
  //   id: 1,
  //   img: box1,
  //   url: "/busy-anglia",
  //   title: "Przewóz osób",
  //   header: "Przewóz osób",
  //   body: "W zakresie przewozu osób obsługujemy teren północno–zachodniej Polski. Osoby odbieramy z adresu i dowozimy pod wskazany adres.\n" +
  //   "Wyjeżdżamy w każdą środę i sobotę."
  // },
  // {
  //   id: 2,
  //   img: box2,
  //   url: "/paczki",
  //   title: "Przewóz paczek",
  //   header: "Przewóz paczek",
  //   body: "Zajmujemy się kompleksową obsługą przesyłek międzynarodowych z Polski do Anglii i odwrotnie.\n" +
  //   "Przewozimy zarówno tradycyjne niewielkie przesyłki jak i sprzęt RTV, AGD, rowery quady, motocykle a także samochody na lawecie.",
  // },
  // {
  //   id: 3,
  //   img: box3,
  //   url: "/cennik/transport-na-lawecie",
  //   title: "Transport na lawaecie",
  //   header: "transport na lawecie",
  //   body: "Oferujemy także transport aut na lawecie, quadów, motocykli między Polską – Anglią – Polską. Do Klienta należy wyłącznie wskazanie adresu odbioru, doręczenia oraz przekazanie dokumentów."
  // },
];

const styles = {
  header: {fontSize: 24, marginBottom: "0.8em"},
  header2: {fontSize: 24, marginBottom: "0.8em", textAlign: 'center'},
  bold: {fontWeight: 'bold'},
  paragraph: {marginBottom: "2em"},
  text: {fontSize: "12px"},
  li: {margin: "auto"},
  greyHeader: {color: "#888", margin: "1rem 0"},
  wrapper: {marginBottom: "2em"},
  list: {margin: '1em auto', listStyle: 'none', display: 'flex', flexDirection: 'row', justifyContent: 'space-around'},
  polandColor: {color: '#f71208'},
  englandColor: {color: '#016bdb'}
};


const slides = [
  // {img: slide1},
  {img: slide2},
  // {img: slide3},
];


const IndexPage = () => (
  <Layout>
    <SEO title="Home"
         keywords={[`Paczki`, `Anglia`, `Paczki Polska Anglia`, `Paczki Anglia`, `UK-TRANS`, `Przewozy Okonek`, `Przewozy-Okonek`, `Przewozy.okonek`, `Przeprowadzki anglia.`]}/>
    <div css={styles.wrapper}>
      <div className="container" css={styles.container}>
      <Slider slides={slides} />
      <div className="container">
        <div className="row justify-content-md-between" css={styles.row}>
          {boxes.map(box => <div className="col-12 col-sm-12 col-lg-4"><Card data={box}/></div>)}
        </div>
      </div>
    </div>
      <div className="container" css={styles.text}>
      <div css={styles.header}>Witaj!
      </div>

      <div css={styles.header}>Oferujemy profesjonalne usługi przewozowe na trasie Polska - Anglia - Polska.
        Zapewniamy szybki i bezpieczny przewóz paczek, sprzętu RTV/AGD, transport materiałów
        budowlanych itp.
      </div>

      <div css={styles.header}>Transport zawsze na wysokim poziomie.</div>

      <div css={styles.header}>W razie pytań i wątpliwości służymy pomocą pod następującymi numerami telefonów:
        <ul css={styles.list}>
          <li css={{...styles.polandColor, ...styles.bold}}><div className="text-center">Polska:</div> +48 513 900 083</li>
          <li css={{...styles.englandColor, ...styles.bold}}><div className="text-center">Anglia:</div> +44 759 818 6916</li>
        </ul>
      </div>

      <div css={styles.header2}>
        Serdecznie zapraszamy do skorzystania z naszych usług – jesteśmy przekonani, że i Ty
        znajdziesz w nas rzetelnego i zaufanego partnera.
      </div>
    </div>
    </div>
  </Layout>
);

export default IndexPage;
