import React from 'react'

import {Link} from 'gatsby'


const styles = {
  card: {
    // minHeight: "70vh",
    border: '1px solid #E0E0E0',
    padding: "1rem",
    marginBottom: "0.5rem",
  },
  header: {
    margin: "0 auto",
    fontWeight: "800",
    textTransform: "uppercase",
    textAlign: 'center'
  },
  text: {
    margin: "1rem auto",
    textAlign: "justify",
    fontSize: "12px",
    height: "120px"
  },
  picture: {
    margin: "0 auto"
  },
  buttonWrapper: {
    margin: "0 auto",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#333",
    },
  },
  button: {
    margin: "0 auto",
    fontSize: "12px",
    padding: "5px 10px",
    color: "#FFF",
    backgroundColor: "#F33F3F",
    "&::after": {
      fontFamily: "FontAwesome",
      content: '\\00BB',
      paddingLeft: "5px",
    },
    "&:hover": {
      backgroundColor: "#333",
    }
  },
  link: {textDecoration: "none"}
};

export default ({data}) => <div className="container d-flex flex-column justify-content-between" css={styles.card}>
  <div css={styles.picture}><img src={data.img} alt={data.title}/></div>
  <div css={styles.header}>{data.header}</div>
  <div css={styles.text}>{data.body}</div>
  <Link css={styles.buttonWrapper} to={data.url}><div css={styles.button}>Czytaj więcej</div></Link>
</div>;