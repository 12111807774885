import React, {Component} from 'react'


const styles = {
  wrapper: {display: "flex", position: "relative", justifyContent: "center", height: '19em'},
  activeSlide: {display: "flex", opacity: "1"},
  inactiveSlide: {display: "flex", opacity: "0"},
  slide: {transition: "all 1s linear 0s", height: '100%', width: '100%', position: 'absolute'},
  dots: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    bottom: "1em",
    width: "5em"
  },
  dot: {
    backgroundColor: "#888",
    border: "1px solid black",
    width: "0.7em",
    height: "0.7em",
    borderRadius: "50%",
    cursor: "pointer"
  },
  activeDot: {backgroundColor: "#f33f3f", border: "1px solid #333"}
};


class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
    };
  }

  handleDotClick(index) {
    this.setState({
      activeSlide: index
    })
  }

  render() {
    const {slides} = this.props;
    const slidesDiv = slides.map((slide, index) =>
      <div
        style={
          {
            ...{
              background: `url(${slide.img}) center/contain no-repeat`,
              ...index === this.state.activeSlide ?
                {...styles.activeSlide, ...styles.slide} :
                {...styles.inactiveSlide, ...styles.slide}
            }
          }}
      >
      </div>);
    const dots = slides.map((slide, index) => <div onClick={e => this.handleDotClick(index)}
                                                   style={index === this.state.activeSlide ? {...styles.dot, ...styles.activeDot} : styles.dot}/>);
    return (
      <div className="d-md-flex d-lg-flex" style={styles.wrapper}>
        {slidesDiv}
        {slides.length > 1 &&
          <div style={styles.dots}>
            {dots}
          </div>
        }
      </div>
    );
  }
}


export default Slider;